<template>
  <el-dialog
    title="修改密码"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      v-if="visible"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="100px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input
          v-model="dataForm.userName"
          placeholder="登录帐号"
          disabled
          style="width: 90%"
        ></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="password">
        <el-input
          v-model="dataForm.password"
          type="password"
          placeholder="原密码"
          style="width: 90%"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          v-model="dataForm.newPassword"
          type="password"
          placeholder="新密码"
          style="width: 90%"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="comfirmPassword">
        <el-input
          v-model="dataForm.comfirmPassword"
          type="password"
          placeholder="确认密码"
          style="width: 90%"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { updatePasswordApi } from "@/api/sys";
import { cryptoAes } from "@/utils/index";

export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    var validateNewPassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 20) {
        callback(new Error("密码长度为6-20位"));
      }
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("新密码不能为空"));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 20) {
        callback(new Error("密码长度为6-20位"));
      }
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("确认密码不能为空"));
      } else if (this.dataForm.newPassword !== value) {
        callback(new Error("确认密码与密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        userName: "",
        password: "",
        newPassword: "",
        comfirmPassword: "",
      },
      dataRule: {
        password: [
          { required: true, message: "原密码不能为空", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { validator: validateNewPassword, trigger: "blur" },
        ],
        comfirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { validator: validateComfirmPassword, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(name) {
      this.dataForm.userName = name;
      this.dataForm.password = "";
      this.dataForm.newPassword = "";
      this.dataForm.comfirmPassword = "";
      this.visible = true;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.dataLoading = true;
          var params = {
            userPwd: cryptoAes(this.dataForm.password),
            newUserPwd: cryptoAes(this.dataForm.comfirmPassword),
          };
          await updatePasswordApi(params)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.visible = false;
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
