import request from '@/utils/request'


// 获取基础产品信息列表
export function serachList (params) {
    return request({
        url: '/product/product/serachList',
        method: 'get',
        params: params
    })
}

// 获取基础产品信息列表
export function list (params) {
    return request({
        url: '/product/product/list',
        method: 'get',
        params: params
    })
}

//获取下拉框产品性能列表
export function getProductPerformance (params) {
    return request({
        url: '/product/product/getProductPerformance',
        method: 'get',
        params: params
    })
}

//获取下拉框其他性能，产品表面,产品光泽
export function getDict (params) {
    return request({
        url: '/product/product/getDict',
        method: 'get',
        params: params
    })
}

//获取行业筛选列表
export function getIndustryList (params) {
    return request({
        url: '/product/product/getIndustryList',
        method: 'get',
        params: params
    })
}
//获取色系列表
export function getColorSeriesList (params) {
    return request({
        url: '/product/product/getColorSeriesList',
        method: 'get',
        params: params
    })
}
//获取产品体系列表
export function getProductHierarchy (params) {
    return request({
        url: '/product/product/getProductHierarchy',
        method: 'get',
        params: params
    })
}

//获取产品系列表
export function getProductSeries (params) {
    return request({
        url: '/product/product/getProductSeries',
        method: 'get',
        params: params
    })
}
//获取产品分类列表
export function getProductCategory (params) {
    return request({
        url: '/product/product/getProductCategory',
        method: 'get',
        params: params
    })
}
//获取工厂列表
export function getFactory (params) {
    return request({
        url: '/product/product/getFactory',
        method: 'get',
        params: params
    })
}
//获取品牌列表
export function getBrand (params) {
    return request({
        url: '/product/product/getBrand',
        method: 'get',
        params: params
    })
}



// 获取信息
export function info (params) {
    return request({
        url: '/product/product/info',
        method: 'get',
        params: params
    })
}

// 添加
export function add (params) {
    return request({
        url: '/product/product/save',
        method: 'post',
        data: params
    })
}

// 修改
export function updateNum (params) {
    return request({
        url: '/product/product/updateNum',
        method: 'post',
        data: params
    })
}

// 修改
export function update (params) {
    return request({
        url: '/product/product/update',
        method: 'post',
        data: params
    })
}

// 删除
export function del (params) {
    return request({
        url: '/product/product/delete',
        method: 'post',
        data: params
    })
}

//获取配方信息
export function selectRecipe (params) {
    return request({
        url: '/product/product/selectRecipe',
        method: 'get',
        params: params
    })
}

//获取当前用户授权设备
export function selectDevice (params) {
    return request({
        url: '/product/product/selectDevice',
        method: 'get',
        params: params
    })
}
//设备同步
export function syncDevice (params) {
    return request({
        url: '/product/product/syncDevice',
        method: 'get',
        params: params
    })
}
//设备同步
export function syncDeviceData (params) {
    return request({
        url: '/product/product/syncDeviceData',
        method: 'get',
        params: params
    })
}
//设备同步
export function syncQueryDeviceData (params) {
    return request({
        url: '/product/product/syncQueryDeviceData',
        method: 'get',
        params: params
    })
}
//根据测试值显示名称
export function selectGloss (params) {
    return request({
        url: '/product/product/selectGloss',
        method: 'get',
        params: params
    })
}
// 导入
export function importExcel(params) {
    return request({
        url: '/product/product/importExcel',
        method: 'post',
        data: params
    })
}
// 根据登录用户获取工厂和品牌
export function getFactoryBrand (params) {
    return request({
        url: '/product/product/getFactoryBrand',
        method: 'get',
        params: params
    })
}
//根据产品编号匹配系列
export function queryNoSeries (params) {
    return request({
        url: '/product/product/getNoSeries',
        method: 'get',
        params: params
    })
}
//根据产品编号匹配系列
export function queryNoColor (params) {
    return request({
        url: '/product/product/getNoColor',
        method: 'get',
        params: params
    })
}
//根据hex获取lab
export function getLAB (params) {
    return request({
        url: '/product/product/getLAB',
        method: 'get',
        params: params
    })
}

//根据lab获取hex
export function gethex (params) {
    return request({
        url: '/product/product/getHex',
        method: 'get',
        params: params
    })
}

// //更改设备状态

// export function syncDevice (params) {
//     return request({
//         url: '/product/product/syncDevice',
//         method: 'get',
//         params: params
//     })
// }

//获取设备信息
export function searchDeviceData (params) {
    return request({
        url: '/product/product/searchDeviceData',
        method: 'get',
        params: params
    })
}

// 获取信息
export function searchProductInfo (params) {
    return request({
        url: '/product/product/searchProductInfo',
        method: 'get',
        params: params
    })
}

// 验证产品号是否重复
export function validateProductNo (params) {
    return request({
        url: '/product/product/validateProductNo',
        method: 'get',
        params: params
    })
}

// 导入
export function importNumExcel(params) {
    return request({
        url: '/product/product/importNumExcel',
        method: 'post',
        data: params
    })
}
