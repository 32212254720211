import request from '@/utils/request'

// 管理员API
export function userList(data) {
  return request({
    url: '/m/sysUser/query',
    method: 'post',
    data
  })
}

export function getUserByIdApi(id) {
  return request({
    url: `/m/sysUser/get/${id}`,
    method: 'get'
  })
}

//获取用户
export function getUserInfoApi () {
    return request({
        url: '/m/sysUser/getInfo',
        method: 'get'
    })
}


export function getRoleListParams(params) {
  return request({
    url: '/sys/role/getRoleListParams',
    method: 'get',
    params
  })
}

export function userDataFormSubmit(data) {
  return request({
    url: '/m/sysUser/addOrUpdate',
    method: 'put',
    data
  })
}

export function userDeleteApi(data) {
  return request({
    url: '/m/sysUser/delete',
    method: 'delete',
    data
  })
}

export function userUpdate(url) {
  return request({
    url,
    method: 'get'
  })
}

// 修改密码
export function updatePasswordApi (params) {
    return request({
        url: '/m/sysUser/updateUserPwd',
        method: 'put',
        data: params
    })
}

// 角色管理API
export function roleInfo(id) {
  return request({
    url: `/m/sysRole/get/${id}`,
    method: 'get'
  })
}

export function queryRoleListApi(data) {
  return request({
    url: '/m/sysRole/query',
    method: 'post',
    data
  })
}

export function roleDataFormSubmit(data) {
  return request({
    url: '/m/sysRole/addOrUpdate',
    method: 'put',
    data
  })
}

export function roleDelete(data) {
  return request({
    url: '/m/sysRole/delete',
    method: 'delete',
    data
  })
}

// 菜单管理API
export function menuInfo(id) {
  return request({
    url: `/m/sysResource/get/${id}`,
    method: 'get'
  })
}

export function menuList(data) {
  return request({
    url: '/m/sysResource/query',
    method: 'post',
    data
  })
}

export function menuDataFormSubmit(data) {
  return request({
    url: '/m/sysResource/addOrUpdate',
    method: 'put',
    data
  })
}

export function menuDelete(id) {
  return request({
    url: `/m/sysResource/delete/${id}`,
    method: 'delete'
  })
}

// 文件上传
export function ossList(params) {
  return request({
    url: '/sys/oss/list',
    method: 'get',
    params
  })
}

export function ossConfig() {
  return request({
    url: '/sys/oss/config',
    method: 'get'
  })
}

export function ossSaveConfig(data) {
  return request({
    url: '/sys/oss/saveConfig',
    method: 'post',
    data
  })
}

export function ossDelete(data) {
  return request({
    url: '/sys/oss/delete',
    method: 'post',
    data
  })
}
// 系统日记
export function logList(params) {
  return request({
    url: '/sys/log/list',
    method: 'get',
    params
  })
}
// 参数管理
export function configList(params) {
  return request({
    url: '/sys/config/list',
    method: 'get',
    params
  })
}

export function configDelete(data) {
  return request({
    url: '/sys/config/delete',
    method: 'post',
    data
  })
}

export function configInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

export function configDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

// 定时任务

export function jobList(params) {
  return request({
    url: '/sys/schedule/list',
    method: 'get',
    params
  })
}

export function jobDelete(data) {
  return request({
    url: '/sys/schedule/delete',
    method: 'post',
    data
  })
}

export function jobPause(data) {
  return request({
    url: '/sys/schedule/pause',
    method: 'post',
    data
  })
}

export function jobResume(data) {
  return request({
    url: '/sys/schedule/resume',
    method: 'post',
    data
  })
}

export function jobRun(data) {
  return request({
    url: '/sys/schedule/run',
    method: 'post',
    data
  })
}

export function jobLogList(params) {
  return request({
    url: '/sys/scheduleLog/list',
    method: 'get',
    params
  })
}

export function jobLogInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

export function jobInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

export function jobDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

//数据库备份列表
export function updatedbList(params) {
    return request({
        url: '/sys/updatedb/list',
        method: 'get',
        params
    })
}

//数据库备份删除
export function updatedbdel (params) {
    return request({
        url: '/sys/updatedb/delete',
        method: 'post',
        data: params
    })
}


// 商城商品分类
// 获取列表
export function categoryList (params) {
  return request({
    url: '/mall/mallgoodscategory/list',
    method: 'get',
    params: params
  })
}

export function categoryList2 (params) {
  return request({
    url: '/mall/mallgoodscategory/list2',
    method: 'get',
    params: params
  })
}

export function categoryListNoPagination(params){
  return request({
    url: '/mall/mallgoodscategory/categoryList',
    method: 'get',
    params: params
  })
}

export function categoryListNoPagination2(params){
  return request({
    url: '/mall/mallgoodscategory/categoryList2',
    method: 'get',
    params: params
  })
}

// 获取信息
export function findCategoryInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

// 保存
export function categoryDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

// 删除
export function delCategory (params) {
  return request({
    url: '/mall/mallgoodscategory/delete',
    method: 'post',
    data: params
  })
}

export function delCategory2 (params) {
  return request({
    url: '/mall/mallgoodscategory/delete2',
    method: 'post',
    data: params
  })
}

// 商品属性
// 获取列表
export function mallGoodsAttrList (params) {
  return request({
    url: '/mall/mallgoodsattr/list',
    method: 'get',
    params: params
  })
}

export function mallGoodsAttrList2 (params) {
  return request({
    url: '/mall/mallgoodsattr/list2',
    method: 'get',
    params: params
  })
}

export function attrListNoPagination(params){
  return request({
    url: '/mall/mallgoodsattr/attrList',
    method: 'get',
    params: params
  })
}

export function attrListNoPagination2(params){
  return request({
    url: '/mall/mallgoodsattr/attrList2',
    method: 'get',
    params: params
  })
}

// 获取信息
export function findMallGoodsAttrInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

// 保存
export function mallGoodsAttrDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

// 删除
export function delMallGoodsAttr(params) {
  return request({
    url: '/mall/mallgoodsattr/delete',
    method: 'post',
    data: params
  })
}


// 商品属性值
// 获取列表
export function mallGoodsAttrValueList (params) {
  return request({
    url: '/mall/mallgoodsattrvalue/list',
    method: 'get',
    params: params
  })
}

export function mallGoodsAttrValueList2 (params) {
  return request({
    url: '/mall/mallgoodsattrvalue/list2',
    method: 'get',
    params: params
  })
}

export function attrValueListByAttrId(params){
  return request({
    url: '/mall/mallgoodsattrvalue/valueListByAttrId',
    method: 'get',
    params: params
  })
}
// 获取信息
export function findMallGoodsAttrValueInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

// 保存
export function mallGoodsAttrValueDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

// 删除
export function delMallGoodsAttrValue(params) {
  return request({
    url: '/mall/mallgoodsattrvalue/delete',
    method: 'post',
    data: params
  })
}

// 商城商品实体表
// 获取列表
export function mallGoodsMainList (params) {
  return request({
    url: '/mall/mallgoodsmain/list',
    method: 'get',
    params: params
  })
}

// 获取采购商城列表
export function mallGoodsMainList2 (params) {
  return request({
    url: '/mall/mallgoodsmain/list2',
    method: 'get',
    params: params
  })
}

// 获取信息
export function findMallGoodsMainInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

// 保存
export function mallGoodsMainDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

// 删除
export function delMallGoodsMainValue(params) {
  return request({
    url: '/mall/mallgoodsmain/delete',
    method: 'post',
    data: params
  })
}

// 上架
export function onShelfByGoodsMain(params) {
  return request({
    url: '/mall/mallgoodsmain/onShelf',
    method: 'post',
    data: params
  })
}

// 下架
export function offShelfByGoodsMain(params) {
  return request({
    url: '/mall/mallgoodsmain/offShelf',
    method: 'post',
    data: params
  })
}
// 上传商品图片
export function uploadMallGoodsImage(params){
  return request({
    url: '/mall/mallgoodsmain/uploadImg',
    method: 'post',
    data: params
  })
}

// 商城订单
// 商城订单列表
export function orderMallBuyList(params){
  return request({
    url: '/mall/ordermallbuy/list',
    method: 'post',
    params: params
  })
}
export function orderMallBuyList2(params){
  return request({
    url: '/mall/ordermallbuy/list2',
    method: 'post',
    params: params
  })
}
// 订单详情
export function findOrderInfo(url){
  return request({
    url: url,
    method: 'get'
  })
}
// 发货物流公司信息
export function findLogisticList(url){
  return request({
    url: url,
    method: 'get'
  })
}
// 发货
export function deliveryOrder(url, data){
  return request({
    url,
    method: 'post',
    data
  })
}

//进入商品推荐页面
export function goodsRecommend(params) {
  return request({
    url: '/mall/mallgoodsrecom/list',
    method: 'get',
    params: params
  })
}

//进入商品推荐页面
export function goodsRecommend2(params) {
  return request({
    url: '/mall/mallgoodsrecom/list2',
    method: 'get',
    params: params
  })
}

//取消商品推荐
export function delMallGoodsRecomValue(params) {
  return request({
    url: '/mall/mallgoodsrecom/delete',
    method: 'post',
    data: params
  })
}

//添加商品推荐
export function addMallGoodsRecomValue(params) {
  return request({
    url: '/mall/mallgoodsrecom/add',
    method: 'post',
    data: params
  })
}

export function addMallGoodsRecomValue2(params) {
  return request({
    url: '/mall/mallgoodsrecom/add2',
    method: 'post',
    data: params
  })
}

//获取所有没被推荐的商品信息
export function mallGoodsNoRecomValue(params) {
  return request({
    url: '/mall/mallgoodsrecom/norecom',
    method: 'get',
    params: params
  })
}

//获取所有没被推荐的商品信息
export function mallGoodsNoRecomValue2(params) {
  return request({
    url: '/mall/mallgoodsrecom/norecom2',
    method: 'get',
    params: params
  })
}

// 获取下拉品牌信息
export function getDataBrand () {
  return request({
    url: '/factory/brand/getDataBrand',
    method: 'get'
  })
}

//获取下拉工厂信息
export function getDataFactory (params) {
  return request({
    url: '/factory/factory/getDataFactory',
    method: 'get',
    params: params
  })
}
