import request from '@/utils/request'

const url = '/m/device'

// 获取列表
export function queryDeviceApi(data) {
  if (!data) {
    data = { pageSize: 100000 }
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getDeviceApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}

// 添加
export function addOrUpdateDeviceApi(params) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data: params
  })
}

// 删除
export function deleteDeviceApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}

/**
 * 获取当前登录人已授权设备信息
 * @returns
 */
export function getUserDeviceApi() {
  return request({
    url: `${url}/getUserDevice`,
    method: 'get'
  })
}

//工厂下拉列表
export function factoryList(params) {
  return request({
    url: '/factory/factory/factorylist',
    method: 'post',
    data: params
  })
}

//获取模型列表
export function getModelListInfoApi(params) {
  return request({
    url: `${url}/getModelListInfo`,
    method: 'get',
    params: params
  })
}

//获取品牌列表
export function getDeviceBrandListInfoApi() {
  return request({
    url: '/m/deviceBrand/list',
    method: 'get'
  })
}

//型号下拉列表
export function getByDeviceBrandIdApi(deviceBrandId) {
  return request({
    url: `/m/deviceModel/get/${deviceBrandId}`,
    method: 'get'
  })
}
