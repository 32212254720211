import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
import store from '../store'
import { getToken, removeToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV==='production'?process.env.VUE_APP_BASE_API:'/api', // api的base_url
  timeout: 100000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  let url=config.url;
  if(config.method === 'get'){
    url.indexOf('?') ===-1 ?config.url=url+'?t='+(new Date().getTime()):config.url=url+'&_='+(new Date().getTime());
  }
  if (store.getters.token) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})



const handleCode = (code, message) => {
  switch (code) {
    // case 401:
    //   Vue.prototype.$baseMessage(message || `后端接口${code}异常`, 'error')
    //   // store.dispatch('user/resetAccessToken').catch(() => {})
    //   // if (loginInterception) {
    //   //   location.reload()
    //   // }
    //   break
    // case 402:
    //   router.push({
    //     path: '/401'
    //   }).catch(() => {})
    //   break
    case 600:
      removeToken()
      store.dispatch('setIsRouters', false)
      router.push({
        path: '/login'
      }).catch(() => {})
    case 601:
      removeToken()
      store.dispatch('setIsRouters', false)
      router.push({
        path: '/login'
      }).catch(() => {})
    default:
      Message({
      message: message || `后端接口${code}异常`,
          type: 'error',
          duration: 3 * 1000
      })
      break
  }
}

// respone interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // if (res.code === 401) {
    //   store.dispatch('FedLogOut').then(() => {
    //     location.reload()
    //   })
    if (res.code === 200) {
      return response.data.data;
    } else {
      handleCode(response.data.code, response.data.message)
      return Promise.reject(
          '请求异常拦截:' +
          JSON.stringify({ code: response.data.code, message: response.data.message }) || 'Error'
      )
    }
  },
  error => {
    console.log('err' + error)// for debug
    Message({
      message: error.message, // ,
      type: 'error',
      duration: 10 * 1000
    })
    return Promise.reject(error)
  })

export default service
